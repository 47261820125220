import React from 'react'
import localforage from 'localforage'

import { Slider } from '../Slider'
import { WooCommerce } from '../../utils/api'

import * as classes from './pr-store.module.scss'

const sliderSettings = {
  slidesToShow: 5,
  slidesToScroll: 5,
  className: classes.storeSlider,
  dotsClass: classes.storeDots,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

interface Product {
  id: number
  name: string
  permalink: string
  images: ReadonlyArray<{
    id: number
    src: string
    alt: string
  }>
}

interface State {
  fetching: boolean
  error: boolean
  data: Product[]
}

export class PRStore extends React.Component<{}, State> {
  state: State = {
    fetching: false,
    error: false,
    data: [],
  }

  fetchProducts = async () => {
    try {
      this.setState({ fetching: true, error: false })

      const data = await WooCommerce.get<Product[]>(
        `/products?consumer_key=${process.env.REACT_APP_WOOCOMMERCE_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WOOCOMMERCE_CONSUMER_SECRET}`
      )

      this.setState({ fetching: false, data })

      await localforage.setItem('products', data)
    } catch {
      this.setState({ error: true })
    } finally {
      this.setState({ fetching: false })
    }
  }

  componentDidMount(): void {
    localforage
      .getItem<Product[]>('products')
      .then((value) => {
        if (value) {
          this.setState({ data: value })
        }
      })
      .then(this.fetchProducts)
      .catch(() => this.setState({ error: true }))
  }

  getImageSrc = (src?: string) => {
    if (src) {
      const fileName = src.slice(0, src.lastIndexOf('.'))
      const ext = src.slice(src.lastIndexOf('.'))

      return `${fileName}-150x150${ext}`
    }
  }

  render() {
    const { data } = this.state

    return (
      <Slider {...sliderSettings}>
        {data.map((product) => (
          <div key={product.id} className={classes.slideContainer}>
            <a
              href={product.permalink}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <div
                className={classes.productContainer}
                style={{
                  backgroundImage: `url(${this.getImageSrc(
                    product.images[0]?.src
                  )})`,
                }}
              />
            </a>
          </div>
        ))}
      </Slider>
    )
  }
}
