import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { graphql, Link, useStaticQuery } from 'gatsby'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'

import { FeaturedNewsListQuery } from '../../types/graphql'

import { fetchArticles } from '../../actions/articles'
import { getFeaturedArticles } from '../../selectors/articles'

import * as classes from './featured-news-list.module.scss'

export const FeaturedNewsList: React.FC = () => {
  const dispatch = useDispatch()
  const featuredNews = useSelector(getFeaturedArticles('news'))
  const { categories } = useStaticQuery<FeaturedNewsListQuery>(graphql`
    query FeaturedNewsList {
      categories: allWordpressCategory {
        nodes {
          slug
          wordpressId: wordpress_id
        }
      }
    }
  `)
  const category = categories.nodes.find((node) => {
    return node.wordpressId === featuredNews.category
  })!

  useEffect(() => {
    if (!featuredNews.fetching && featuredNews.data.length < 8) {
      dispatch(fetchArticles('news'))
    }
  }, [featuredNews.fetching, featuredNews.data.length])

  return (
    <div className={classes.list}>
      {featuredNews.data.slice(4, 8).map((article) => (
        <Row
          as={Link}
          className={classes.listItem}
          key={article.id}
          to={`/news/${category.slug}/${article.slug}`}
        >
          <Col className={classes.imageContainer} xs={4}>
            <img
              className={classes.image}
              src={article.featuredImage?.thumbnail}
            />
          </Col>
          <Col xs={8}>
            <h5 className={classes.title}>{parse(article.title)}</h5>
            <p className={classes.date}>
              {moment(article.date).format('D MMMM YYYY')}
            </p>
          </Col>
        </Row>
      ))}
    </div>
  )
}
