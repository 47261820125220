import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link } from 'gatsby'
import InfiniteScroll from 'react-infinite-scroller'
import { PersistGate } from 'redux-persist/integration/react'
import { useStaticQuery, graphql } from 'gatsby'

import { CategoriesQuery } from '../../types/graphql'

import { Button } from '../Button'
import { NewsGrid } from '../NewsGrid'
import { Spinner } from '../Spinner'

import { NewsCategory } from '../../actions/articles'
import { fetchArticles, fetchArticlesUpdates } from '../../actions/articles'
import { getCategoryArticles } from '../../selectors/articles'
import { persistor } from '../../containers/Redux'

import * as classes from './news.module.scss'

const PADDING_SIZE = 8

export interface NewsFeedProps {
  category: NewsCategory
  gridUpdateHook?: () => void
  preview?: boolean
}

export const NewsFeed: React.FC<NewsFeedProps> = ({
  category,
  gridUpdateHook,
  preview = false,
}) => {
  const dispatch = useDispatch()

  const { categories } = useStaticQuery<CategoriesQuery>(graphql`
    query Categories {
      categories: allWordpressCategory {
        nodes {
          slug
          wordpressId: wordpress_id
        }
      }
    }
  `)

  const news = useSelector(getCategoryArticles(category), shallowEqual)

  const fetchNews = useCallback(() => {
    dispatch(fetchArticles(category))
  }, [dispatch, news.data.length])

  const fetchNewsUpdates = useCallback(() => {
    dispatch(fetchArticlesUpdates(category))
  }, [dispatch])

  useEffect(() => {
    if (news.data.length > 0) {
      fetchNewsUpdates()
    }
  }, [])

  const contents = preview ? news.data.slice(0, 6) : news.data

  const articlesCategoryName = categories.nodes.find((node) => {
    return node.wordpressId === news.category
  })

  return (
    <div className={classes.container}>
      <PersistGate loading={null} persistor={persistor}>
        <InfiniteScroll
          loadMore={fetchNews}
          hasMore={
            news.hasMoreContent &&
            !news.fetching &&
            !news.error &&
            (!preview || news.data.length === 0)
          }
          loader={
            <div style={{ clear: 'both' }} key={'loader'}>
              Loading...
            </div>
          }
          className={classes.news}
        >
          <NewsGrid
            category={articlesCategoryName?.slug!}
            contents={contents}
            padding={PADDING_SIZE}
            onItemsRendered={gridUpdateHook}
          />

          <Spinner show={news.fetching} />

          {!news.hasMoreContent && !preview && (
            <div>
              <p>Hai visto tutti gli articoli</p>
            </div>
          )}
        </InfiniteScroll>
      </PersistGate>

      {preview && (
        <div className={classes.buttonContainer}>
          <Button
            as={Link}
            to={`/news/${articlesCategoryName?.slug!}`}
            className={classes.button}
          >
            Di più
          </Button>
        </div>
      )}
    </div>
  )
}
