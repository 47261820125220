import React, { useState } from 'react'

import { useInterval } from '../../utils/hooks'

const formatTime = (time: number) => {
  return `00${time}`.slice(-2)
}

const formatRemainingTime = (time: number) => {
  const seconds = Math.floor((time % (1000 * 60)) / 1000)
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const days = Math.floor(time / (1000 * 60 * 60 * 24))

  return `${formatTime(days)}:${formatTime(hours)}:${formatTime(
    minutes
  )}:${formatTime(seconds)}`
}

interface Props {
  to: number
}

export const CountDown = ({ to }: Props) => {
  const [remainingTime, setRemainingTime] = useState(to - Date.now())

  useInterval(() => {
    setRemainingTime(to - Date.now())
  }, 1000)

  return <p>{formatRemainingTime(remainingTime)}</p>
}
