import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import { Slider } from '../Slider'

import { fetchArticles } from '../../actions/articles'
import { getFeaturedArticles } from '../../selectors/articles'

import iconPost from '../../images/post-details-icon.svg'

import * as classes from './featured-news-slider.module.scss'

export const FeaturedNewsSlider: React.FC = () => {
  const dispatch = useDispatch()
  const featuredNews = useSelector(getFeaturedArticles('news'))

  useEffect(() => {
    if (!featuredNews.fetching && featuredNews.data.length < 4) {
      dispatch(fetchArticles('news'))
    }
  }, [featuredNews.fetching, featuredNews.data.length])

  return (
    <Slider className={classes.slider}>
      {featuredNews.data.slice(0, 4).map((post) => (
        <div className={classes.post} key={post.id}>
          <div
            style={{
              backgroundImage: `url(${post?.featuredImage?.full})`,
            }}
            className={classes.newsBackground}
          >
            <Link to={`/news/tutte-le-news/${post.slug}`}>
              <h2>{parse(post.title)}</h2>
            </Link>
          </div>
          <Link to={`/news/tutte-le-news/${post.id}`}>
            <img className={classes.detailsIcon} src={iconPost} />
          </Link>
        </div>
      ))}
    </Slider>
  )
}
