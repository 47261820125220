import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { CountDown } from './Countdown'
import { TeamLogo } from '../TeamLogo'

import { getGameDate } from '../../utils'

import * as classes from './schedule.module.scss'
import { Calendar } from '../../reducers/calendar'

const getGameTitle = (date: string) => {
  const gameDate = new Date(date).setHours(0, 0, 0, 0).valueOf()
  const currentDate = new Date().setHours(0, 0, 0, 0).valueOf()

  if (gameDate === currentDate) {
    return 'Il match di oggi'
  } else if (gameDate > currentDate) {
    return 'Prossimo match'
  } else {
    return 'Ultimo match'
  }
}

interface Props {
  data: Calendar
}

export const Match = React.memo(({ data }: Props) => {
  const gameDate = getGameDate(data.date, data.time)

  return (
    <div className={classes.match}>
      <Row className={classes.header}>
        <Col xs={5} className={classes.column}>
          <p>{getGameTitle(data.date)}</p>
        </Col>

        <Col xs={5} className={classes.column}>
          <p>{new Date(data.date).toLocaleDateString()}</p>
        </Col>

        <Col xs={2} className={classes.column}>
          <p>{data.time}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={4} className={classes.column}>
          <TeamLogo
            teamId={data.homeTeamId}
            teamLabel={data.homeTeam}
            fluid
            white
          />
        </Col>

        <Col xs={4} className={classes.score}>
          {new Date(gameDate) > new Date() ? (
            <CountDown to={gameDate} />
          ) : (
            <p>
              {data.homeScore} - {data.awayScore}
            </p>
          )}
        </Col>

        <Col xs={4} className={classes.column}>
          <TeamLogo
            teamId={data.awayTeamId}
            teamLabel={data.awayTeam}
            fluid
            white
          />
        </Col>
      </Row>
    </div>
  )
})
