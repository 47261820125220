import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import moment from 'moment'

import { FeaturedNewsList } from '../FeaturedNewsList'
import { Match } from './Match'
import { Spinner } from '../Spinner'

import { fetchCalendar } from '../../actions/calendar'

import { getCalendar } from '../../selectors/calendar'

export const Schedule = () => {
  const dispatch = useDispatch()
  const schedule = useSelector(getCalendar, shallowEqual)

  React.useEffect(() => {
    dispatch(fetchCalendar())
  }, [dispatch])

  if (Object.values(schedule.data).length === 0) {
    return <Spinner show={schedule.fetching} />
  }

  if (schedule.currentDay < 0) {
    return <FeaturedNewsList />
  }

  const today = moment(new Date())
  const games = Object.values(schedule.data).reduce(
    (accumulator, matches) => [...accumulator, ...matches],
    []
  )
  const prGames = games.filter((game: any) =>
    [game.homeTeamId, game.awayTeamId].includes(
      process.env.REACT_APP_RE_TEAM_ID
    )
  )
  const nextGameIndex = prGames.findIndex(
    (game: { date: moment.MomentInput }) => moment(game.date).isAfter(today)
  )

  return (
    <div>
      {nextGameIndex >= 0 && <Match data={prGames[nextGameIndex]} />}

      {nextGameIndex > 0 && <Match data={prGames[nextGameIndex - 1]} />}
    </div>
  )
}
