import React from 'react'
import SlickSlider, { Settings, CustomArrowProps } from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as classes from './slider.module.scss'

interface ArrowProps extends CustomArrowProps {
  icon: IconProp
  direction: 'right' | 'left'
}

const Arrow = ({ icon, direction, onClick }: ArrowProps) => (
  <div
    className={classNames(classes.arrow, classes[direction])}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} />
  </div>
)

const settings = {
  autoplay: true,
  autoplaySpeed: 40000,
  dots: true,
  dotsClass: classes.navigation,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow direction={'right'} icon={faChevronRight} />,
  prevArrow: <Arrow direction={'left'} icon={faChevronLeft} />,
}

interface Props extends Settings {
  children: React.ReactNode
}

export const Slider = ({ className, ...props }: Props) => (
  <SlickSlider
    className={classNames(classes.slider, className)}
    {...settings}
    {...props}
  />
)
