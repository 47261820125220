// extracted by mini-css-extract-plugin
export var bannerButton = "home-module--banner-button--PaGfn";
export var data = "home-module--data--7aEkp";
export var description = "home-module--description--913jj";
export var descriptionContainer = "home-module--description-container--IrDQk";
export var featuredNewsSlider = "home-module--featuredNewsSlider--+HJ82";
export var home = "home-module--home--xuRvf";
export var match = "home-module--match--OAmoC";
export var news = "home-module--news--siRM6";
export var nextMatch = "home-module--nextMatch--mY0ND";
export var prStore = "home-module--prStore--J-31X";
export var schedule = "home-module--schedule--ojXL8";
export var score = "home-module--score--1oW6z";
export var ticketsButton = "home-module--tickets-button--0JBiu home-module--banner-button--PaGfn";
export var title = "home-module--title--XZSgf";